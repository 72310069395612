// import styles from './Header.modules.scss';selectedCrypto
import './Content.scss';
import CustomDropdown from './CustomDropdown.js';
import DropdownCurrency from './DropdownCurrency.js';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import ads1 from '../img/ads2.jpg';
import ads2 from '../img/banner_7.gif';
import axios from 'axios';
// import { BiSolidContact } from "react-icons/bi";
import cloneDeep from 'lodash/cloneDeep';
function ContentGovnoCod({theme, setTheme, currencyCrypt, currency}) {

    // Вытаскиваем из дочерних Курс значение и Валюту

    const [selectedUnit, setSelectedUnit] = useState('');
    const [selectedCrypto, setSelectedCrypto] = useState(currencyCrypt);
    const handleUnitChange = (newUnit) => {
        setSelectedUnit(newUnit);
    };

    const handleCryptoChange = (newCrypto) => {
        setSelectedCrypto(newCrypto);
    };

    const [selectedCurrency, setSelectedCurrency] = useState(currency);

    const handleCurrencyChange = (newCurrency) => {
        setSelectedCurrency(newCurrency);
    };
    const [lastChanged, setLastChanged] = useState("main"); // main или result
// Конец
// Доп значения для Юнит
const [previousUnit, setPreviousUnit] = useState('');
const [previousInputValue, setPreviousInputValue] = useState('');



    const hiddenInputRef = React.useRef(null);
    const [inputValue, setInputValue] = React.useState("1");

    const handleChange = (e) => {
        // const element = document.querySelector('.inputCountResult');
        // element.classList.add('load')
        // element.classList.remove('load')
        setInputValue(e.target.value);
        setPreviousInputValue(e.target.value);
        setPreviousUnit(selectedUnit);
        setLastChanged("main");
    }
    
    const handleChangeResult = (e) => {
        setInputValueResult(e.target.value);
        setLastChanged("result");
    }
    


    React.useEffect(() => {
        if (hiddenInputRef.current) {
            document.querySelector(".inputCount").style.width = `${hiddenInputRef.current.scrollWidth}px`;
        }
    }, [inputValue]);
    // ==================Result==============
    const hiddenInputRefResult = React.useRef(null);
    const [inputValueResult, setInputValueResult] = React.useState("");


    React.useEffect(() => {
        if (hiddenInputRefResult.current) {
            document.querySelector(".inputCountResult").style.width = `${hiddenInputRefResult.current.scrollWidth}px`;
        }
    }, [inputValueResult]);


    // ClipBoard
    const handleInputClick = (e) => {
        e.target.select();
    }
    


// Нормальный гет
React.useEffect(() => {
    console.log(currencyCrypt);
    console.log(currency);
    // const result = async () => {await axios.get(`http://127.0.0.1:8000/api/?from=${currencyCrypt}&to=${currency}`).then((result) => {
    const result = async () => {await axios.get(`/api_convert/?from=${currencyCrypt}&to=${currency}`).then((result) => {
    setInputValueResult(parseFloat(inputValue) * parseFloat(result.data.rate)); 
    setInputValue(1)
}
)}
    result();
}, [])

let intervalId;




React.useEffect(() => {
    if (lastChanged !== "main") return;
    clearInterval(intervalId);
    if (inputValue && selectedCrypto && selectedCurrency) {
        const oldInput = cloneDeep(inputValue);
        const fetchExchangeRate = async () => {
            try {
                // Отправляем запрос к API
                const element = document.querySelector('.inputCountResult');
                element.classList.add('load')
                // const response = await axios.get('http://127.0.0.1:8000/api/', {
                const response = await axios.get('/api_convert/', {
                    params: {
                        from: selectedCrypto.toLowerCase(),
                        to: selectedCurrency.toLowerCase()
                    }
                });
                if (inputValue !== oldInput){
                    
                }
                // Если ответ успешный, обновляем состояние с полученным значением
                if (response.data.rate) {
                    // предположим, что ответ содержит поле rate с курсом конвертации
                    let result = parseFloat(inputValue) * parseFloat(response.data.rate);
                    switch (selectedUnit){
                        case 'µ':
                            result /= 1000000;
                            break;
                            case 'm':
                                result /= 1000;
                                break;
                                case 'k':
                                    result /= 0.001;
                                    break;
                    }
                    console.log(result);
                    if (oldInput == hiddenInputRef.current.value){
                        setInputValueResult(result);  // округляем результат до двух знаков после запятой
                        element.classList.remove('load')
                    }
                    // console.log(hiddenInputRef.current.value);
                    // console.log(oldInput);
                    // setInputValue(inputValue);
                    
                    console.log("TO");
                }
            } catch (error) {
                console.error("Ошибка при получении курса обмена:", error);
            }
        };

        fetchExchangeRate();
        // Установка интервала для обновления каждые 3 секунды
        const intervalId = setInterval(fetchExchangeRate, 5000);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);


    }
}, [inputValue, selectedCrypto, selectedCurrency]);

useEffect(() => {
    if (lastChanged !== "result") return;
    if (inputValueResult && selectedCrypto && selectedCurrency) {
        const oldInputResult = cloneDeep(inputValueResult);
        const fetchExchangeRateForReverse = async () => {
                try {
                    // Отправляем запрос к API
                    const element = document.querySelector('.inputCount');
                    element.classList.add('load')
                    // const response = await axios.get('http://127.0.0.1:8000/api/', {
                    const response = await axios.get('/api_convert/', {
                        params: {
                            from: selectedCurrency.toLowerCase(),   
                            to: selectedCrypto.toLowerCase()
                        }
                    });
    
                    // Если ответ успешный, обновляем состояние с полученным значением
                    if (response.data.rate) {
                        let result = parseFloat(inputValueResult) * parseFloat(response.data.rate); 
                        switch (selectedUnit){
                            case 'µ':
                                result *= 1000000;
                                break;
                                case 'm':
                                    result *= 1000;
                                break;
                                case 'k':
                                    result *= 0.001;
                                break;
                        }
                        if (oldInputResult == hiddenInputRefResult.current.value){
                            element.classList.remove('load')
                            setInputValue(result.toFixed(8));
                        }
                        console.log("FROM");
                    }
                } catch (error) {
                    console.error("Ошибка при получении курса обмена:", error);
                }
            
        };

        fetchExchangeRateForReverse();

        

        const intervalId = setInterval(fetchExchangeRateForReverse, 5000);
        return () => clearInterval(intervalId);

        
        
    }
}, [inputValueResult, selectedCrypto, selectedCurrency]);

// Нормальный гет КОНЕЦ

// Юнит
useEffect(() => {
    let newInputValue = previousInputValue;
    let unitFirst = previousUnit;
    console.log(newInputValue);
    switch (selectedUnit) {
        case 'µ':
            newInputValue *= 1000000;
            break;
            case 'm':
            newInputValue *= 1000;
            break;
            case 'k':
            newInputValue *= 0.001;
            break;
            case '•':
            newInputValue = previousInputValue;
            break;
            default:
                newInputValue = previousInputValue;
                break;
    }
    // Если предыдущая единица была установлена (не пустая строка)
    if (previousUnit) {
        switch (previousUnit) {
            case 'µ':
                newInputValue *= 1 / 1000000;
                break;
            case 'm':
                newInputValue *= 1 / 1000;
                break;
            case 'k':
                newInputValue *= 1 / 0.001;
                break;
            default:
                break;
        }
    }
    setInputValue(newInputValue);
}, [selectedUnit]);
// tableData убрала пушо хз нахуй оно там было МБ нужно оьратно будет добавить
// Конец







    return (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div className={ theme == 'light' ? 'boxConverter' :  'boxConverter boxConverterDark' }>
                
                <div className={ theme == 'light' ? 'values' :  'values valuesDark' }>
                    <input 
                        type="text" 
                        className='inputCount' 
                        value={inputValue}
                        onChange={handleChange}
                        onClick={handleInputClick}

                    />
                    <input 
                        ref={hiddenInputRef}
                        type="text" 
                        className='hiddenInput' 
                        value={inputValue}
                        readOnly
                    />
                    < CustomDropdown  
                    onUnitChange={handleUnitChange} 
                    onCryptoChange={handleCryptoChange}
                    theme = {theme} 
                    setTheme = {setTheme}
                    currencyCrypt = {currencyCrypt} 
                    currency = {currency}
                    />
                    
                </div>
                <p>=</p>
                <div className={ theme == 'light' ? 'values' :  'values valuesDark' }>
                <input 
                        type="text" 
                        className='inputCountResult' 
                        value={inputValueResult}
                        onChange={handleChangeResult}
                        onClick={handleInputClick}

                    />
                    <input 
                        ref={hiddenInputRefResult}
                        type="text" 
                        className='hiddenInputResult' 
                        value={inputValueResult}
                        readOnly
                    />
                    < DropdownCurrency  
                    onCurrencyChange={handleCurrencyChange}
                    theme = {theme} 
                    setTheme = {setTheme}
                    
                    currencyCrypt = {currencyCrypt} 
                    currency = {currency}
                    />
                </div>
            </div>
        </div>

    );
}

export default ContentGovnoCod;

