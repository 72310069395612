import React, { useState, useEffect, useRef } from 'react';
import './CustomDropdown.scss';

function CustomDropdown({theme, setTheme, onUnitChange, onCryptoChange, currencyCrypt, currency}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [unit, setUnit] = useState('');
    const [mainSelection, setMainSelection] = useState(currencyCrypt.toUpperCase());

    const dropdownRef = useRef(null);

    const options = [
        { label: 'Bitcoin', value: 'BTC' },
        { label: 'Ethereum', value: 'ETH' },
        { label: 'Litecoin', value: 'LTC' }
    ];


    const handleSelect = (type, value) => {
        if (type === "unit") {
            setUnit(value);
            onUnitChange && onUnitChange(value); // передаем значение в родительский компонент
        } else {
            setUnit('');
            setMainSelection(value);
            console.log(value);
            onCryptoChange && onCryptoChange(value); // передаем значение в родительский компонент
        }
        setIsOpen(false);
    };



    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);




    

    // ...
    return (
        <div className={ theme == 'light' ? 'customDropdown' :  'customDropdown darkcustomDropdown' } ref={dropdownRef}>
            <div className="currentOption" onClick={() => setIsOpen(!isOpen)}>
                {unit}{mainSelection}
            </div>
            {isOpen && (
                <div className="optionsListCrypto">
                    <div className="unitOptions">
                        <span className='click' onClick={() => handleSelect("unit", "µ")}>µ</span>
                        <span className='click' onClick={() => handleSelect("unit", "m")}>m</span>
                        <span className='click' onClick={() => handleSelect("unit", "•")}>•</span>
                        <span className='click' onClick={() => handleSelect("unit", "k")}>k</span>
                    </div>
                    {options
                        .filter(obj => obj.label.toLowerCase().includes(searchValue.toLowerCase()))
                        .slice(0, 4)  // Эта строка ограничивает отображение только первыми 4 элементами
                        .map(option => (
                            <div key={option.value} className="option" onClick={() => handleSelect("main", option.value)}>
                                {option.label} <span className="code">{option.value}</span>
                            </div>
                        ))}

                </div>
            )}
        </div>
    );
    
    // ...
    
}

export default CustomDropdown;
