// import styles from './Header.modules.scss';
import './Header.scss';
import { BiSolidContact, BiSun, BiMoon } from "react-icons/bi";
import { useState } from 'react';


function Header({theme, setTheme}) {


  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };
  


  return (
        <header className={ theme == 'light' ? 'header' :  'header2 dark' }>
            <div className='tab'></div>
            <div className='tab'><p className={ theme == 'light' ? 'p' :  'p dark' }>Simple Crypto Converter</p></div>
            <div className='tabLeft'>
                <div className="box">
                    <div onClick={toggleTheme} style={{cursor: 'pointer'}}>
                        {theme === 'light' ? <BiSun style={{fontSize: '30px', color: 'rgb(156, 155, 155)'}} /> : <BiMoon style={{fontSize: '30px', color: 'rgb(156, 155, 155)'}} />}
                    </div>
                    <a className='ml-15' href="#FooterId">
                        <BiSolidContact style={{fontSize: '30px', color: 'rgb(156, 155, 155)'}}/>
                    </a>
                </div>
            </div>
            
        </header>
  );
}





export default Header;
