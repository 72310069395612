import React, { useState, useEffect, useRef, useCallback } from 'react';
import './DropdownCurrency.scss';

function DropdownCurrency({theme, setTheme, onCurrencyChange, currencyCrypt, currency}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(currency.toUpperCase());
    const dropdownRef = useRef(null);
    const options = [
        { label: 'Euro', value: 'EUR' },
        { label: 'British Pound', value: 'GBP' },
        { label: 'Canadian Dollar', value: 'CAD' },
        { label: 'Chinese Yuan Renminb', value: 'CNY' },
        { label: 'United States Dollar', value: 'USD' }
    ];

    const handleClickOutside = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div className={ theme == 'light' ? 'customDropdown' :  'customDropdown darkcustomDropdown' }
        ref={dropdownRef}>
            <div className="currentOption" onClick={() => setIsOpen(!isOpen)}>
                {selectedValue}
            </div>
            {isOpen && (
                <div className="optionsList">
                    <div>
                        {options
                            .filter(obj => obj.label.toLowerCase().includes(searchValue.toLowerCase()))
                            .map(option => (
                                <div key={option.value} className="option" onClick={() => {
                                    setSelectedValue(option.value);
                                    setIsOpen(false);
                                    onCurrencyChange && onCurrencyChange(option.value); // передаем значение в родительский компонент
                                }}>
                                    {option.label} <span className="code">{option.value}</span>
                                </div>
                        ))}
                    </div>
                </div>
            )}
            {/* {isOpen && (
                <div className="optionsList">
                    <div>
                        {options
                            .filter(obj => obj.label.toLowerCase().includes(searchValue.toLowerCase()))
                            .map(option => (
                                <div key={option.value} className="option" onClick={() => {
                                    setSelectedValue(option.value);
                                    setIsOpen(false);
                                }}>
                                    {option.label} <span className="code">{option.value}</span>
                                </div>
                        ))}
                    </div>
                </div>
            )} */}
        </div>
    );
}

export default DropdownCurrency;
