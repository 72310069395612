import './styles.scss';
import './App.scss';
import Header from './components/Header.js';
import Content from './components/Content.js';
import Footer from './components/Footer.js';
import { useState, useEffect } from 'react';

function App() {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (theme === 'light') {
      document.body.style.background = ''; // устанавливает фон в дефолтное значение
    } else {
      document.body.style.background = 'rgb(27, 27, 27)';
    }
  }, [theme]);

  return (
    <div className={ theme == 'light' ? 'App' :  'App AppDark' }>
        <Header theme = {theme} setTheme = {setTheme}/>
        <Content theme = {theme} setTheme = {setTheme}/>
        <Footer theme = {theme} setTheme = {setTheme}/>
        
    </div>
  );
}

export default App;
