

import React from 'react';
import './Footer.scss';
import AOS from 'aos';
import 'aos/dist/aos.css'; // импорт стилей
import { useEffect } from 'react';
// BiSolidPhone
import { BiSolidPhone, BiEnvelope } from "react-icons/bi";



function Footer({theme, setTheme}) {


  
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    // <div data-aos="fade-up"
    //  data-aos-duration="3000"></div>
    <footer data-aos="fade-up" data-aos-duration="9000" id='FooterId'
    className={ theme == 'light' ? 'footer' :  'footerDark' }>
        <h3 className="footerTitle">Contact Us</h3>
        <div className='df'
        style={{width: '50%'}}
        > 
          <div className="contactItem">    
              <div className='contactIcon'>
                <BiEnvelope style={{width: '20px', margin: '5px'}} /> Telegram: 
              </div>        
              https://t.me/kaggm
          </div>
          <div className="contactItem">
              <div className='contactIcon'>
                <BiSolidPhone style={{width: '20px', margin: '5px'}}/> Telegram: 
              </div>
              https://t.me/kaggm
          </div>
        </div>
    </footer>
  );
}


export default Footer;


