// import styles from './Header.modules.scss';
import './Content.scss';
import CustomDropdown from './CustomDropdown.js';
import ContentGovnoCod from './ContentGovnoCod.js';
import DropdownCurrency from './DropdownCurrency.js';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import ads1 from '../img/ads2.jpg';
import ads2 from '../img/banner_7.gif';
import axios from 'axios';
// import { BiSolidContact } from "react-icons/bi";
import cloneDeep from 'lodash/cloneDeep';
function Content({theme, setTheme}) {

// Реклама старт

const [tableData, setTableData] = useState([]);


useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Сброс часов, минут, секунд и миллисекунд

    // axios.get('http://127.0.0.1:8080/get_table_ads_data/')
    axios.get('/api_ad/get_table_ads_data/')
    .then(response => {
        console.log("Raw data:", response.data);
        const filteredAds = response.data.filter(ad => {
            const date1 = new Date(ad.date1);
            date1.setHours(0, 0, 0, 0);
            const date2 = new Date(ad.date2);
            date2.setHours(0, 0, 0, 0);
            return date1 <= today && date2 >= today;
            // const date1 = new Date(ad.date1);
            // const date2 = new Date(ad.date2);
            // return date1 <= today && date2 >= today;
        });

        // Группировка по rowIndex
        let rows = {};
        filteredAds.forEach(ad => {
            if (!rows[ad.rowIndex]) {
                rows[ad.rowIndex] = [];
            }
            rows[ad.rowIndex][ad.colIndex] = ad;
        });
        const result = Object.values(rows).filter(row => row.length > 0);
        
        console.log("Filtered and Grouped Data", result);
        setTableData(result);
    })
    .catch(error => {
        console.log("Error fetching table data", error);
    });
}, []);



// Реклама FINISH SUKA



 











    return (
        <div className={ theme == 'light' ? 'contentContainer' :  'contentContainer dark' }>


{/* ================================Ads====================================== */}


        <div className="adsContent">
            {tableData.map((row, rowIndex) => (
                <div key={rowIndex} className="BannerColumn">
                    {row.map((ad, colIndex) => (
                        <a href={ad.link} target="_blank" rel="noopener noreferrer" key={colIndex}>
                            <img className='gifs' src={ad.value} alt={`Ad ${rowIndex}-${colIndex}`} />
                        </a>
                        // <img key={colIndex} className='gifs' src={ad.value} alt={`Ad ${rowIndex}-${colIndex}`} />
                    ))}
                </div>
            ))}
        </div>

{/* 
            <div className="adsContent">
                {tableData.map((row, rowIndex) => (
                    <div key={rowIndex} className="BannerColumn">
                        {row.map((cellValue, cellIndex) => (
                            <img key={cellIndex} className='gifs' src={cellValue.value} alt={cellValue} />
                        ))}
                    </div>
                ))}
            </div> */}



{/* ================================END Ads====================================== */}

            {/* btc - usd
btc - cny
ltc - usd
ltc - cny */}

            <ContentGovnoCod theme = {theme} setTheme = {setTheme} currencyCrypt = {"btc"} currency = {"usd"}/>
            <ContentGovnoCod theme = {theme} setTheme = {setTheme} currencyCrypt = {"btc"} currency = {"cny"}/>
            <ContentGovnoCod theme = {theme} setTheme = {setTheme} currencyCrypt = {"ltc"} currency = {"usd"}/>
            <ContentGovnoCod theme = {theme} setTheme = {setTheme} currencyCrypt = {"ltc"} currency = {"cny"}/>

{/* 
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div className={ theme == 'light' ? 'boxConverter' :  'boxConverter boxConverterDark' }>
                
                <div className={ theme == 'light' ? 'values' :  'values valuesDark' }>
                    <input 
                        type="text" 
                        className='inputCount' 
                        value={inputValue}
                        onChange={handleChange}
                        onClick={handleInputClick}

                    />
                    <input 
                        ref={hiddenInputRef}
                        type="text" 
                        className='hiddenInput' 
                        value={inputValue}
                        readOnly
                    />
                    < CustomDropdown  
                    onUnitChange={handleUnitChange} 
                    onCryptoChange={handleCryptoChange}
                    theme = {theme} 
                    setTheme = {setTheme}/>
                    
                </div>
                <p>=</p>
                <div className={ theme == 'light' ? 'values' :  'values valuesDark' }>
                <input 
                        type="text" 
                        className='inputCountResult' 
                        value={inputValueResult}
                        onChange={handleChangeResult}
                        onClick={handleInputClick}

                    />
                    <input 
                        ref={hiddenInputRefResult}
                        type="text" 
                        className='hiddenInputResult' 
                        value={inputValueResult}
                        readOnly
                    />
                    < DropdownCurrency  
                    onCurrencyChange={handleCurrencyChange}
                    theme = {theme} 
                    setTheme = {setTheme}
                    />
                </div>
            </div>
        </div> */}

        </div>
    );
}

export default Content;

